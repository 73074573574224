<template>
  <div class="side-bar">
    <div class="side-bar__header">
      <slot name="header"></slot>
    </div>
    <input
      v-if="showSearch"
      type="text"
      class="side-bar__search"
      placeholder="Search"
      v-model="search"
    />
    <div
      class="side-bar__item"
      v-for="(item, itemIndex) in filteredList"
      :key="itemIndex"
    >
      <router-link
        v-if="item.link !== 'logout' && item.link !== 'knowledgeBase'"
        :to="item.link"
        class="side-bar__link"
        :class="{ 'side-bar__link--active': $route.path === item.link }"
      >
        <inline-svg
          class="side-bar__link-icon"
          v-if="item.icon"
          :src="require(`../assets/icons/Slides.svg`)"
          :aria-label="item.icon"
        ></inline-svg>
        {{ item.name }}
      </router-link>
      <a
        v-else-if="item.link === 'knowledgeBase'"
        class="side-bar__link"
        href="https://support.lobbyspace.me/"
        target="_blank"
      >
        Knowledge Base
      </a>
      <a
        v-else-if="item.link === 'logout'"
        @click="logout"
        class="side-bar__link"
      >
        Logout
      </a>
    </div>
    <div class="side-bar__footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    showSearch: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    search: ""
  }),
  computed: {
    filteredList() {
      if (this.showSearch) {
        return this.list.filter(item => {
          return item.name.toLowerCase().includes(this.search.toLowerCase());
        });
      }

      return this.list;
    }
  },
  methods: {
    ...mapActions("user", ["logout"])
  }
};
</script>

<style lang="scss">
.side-bar {
  height: 100vh;
  width: 208px;
  @apply border-l border-r border-ls-gray-200 bg-white fixed p-4;

  &__item {
    display: flex;
    &:hover {
      @apply bg-ls-gray-100;
      border-radius: 8px;
    }
  }

  &__link {
    @apply text-ls-gray-800 font-semibold text-xs cursor-pointer p-2 flex;
    width: 100%;

    &--active {
      @apply text-ls-purple;

      .side-bar__link-icon {
        @apply text-ls-purple;
        path {
          @apply fill-current;
        }
      }
    }

    &-icon {
      margin-right: 8px;
    }
  }

  &__footer {
    @apply flex flex-col;
  }
}
</style>
