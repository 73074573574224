<template>
  <div class="mobile-side-bar">
    <div class="mobile-side-bar__current" @click="showSideBar = !showSideBar">
      {{ currentPage }}
      <inline-svg
        class="mobile-side-bar__current-icon"
        :class="{ 'mobile-side-bar__current-icon--active': showSideBar }"
        :src="require(`../assets/icons/Down.svg`)"
        aria-label="Down"
      ></inline-svg>
    </div>
    <div class="mobile-side-bar__links" v-if="showSideBar">
      <div
        class="mobile-side-bar__item"
        v-for="(item, itemIndex) in filteredList"
        :key="itemIndex"
        @click="showSideBar = false"
      >
        <router-link
          v-if="item.link !== 'logout' && item.link !== 'knowledgeBase'"
          :to="item.link"
          class="mobile-side-bar__link"
          :class="{
            'mobile-side-bar__link--active': $route.path === item.link
          }"
        >
          <inline-svg
            class="mobile-side-bar__link-icon"
            v-if="item.icon"
            :src="require(`../assets/icons/${item.icon}.svg`)"
            :aria-label="item.icon"
          ></inline-svg>
          {{ item.name }}
        </router-link>
        <a
          v-else-if="item.link === 'knowledgeBase'"
          class="mobile-side-bar__link"
          href="https://support.lobbyspace.me/"
          target="_blank"
        >
          Knowledge Base
        </a>
        <a
          v-else-if="item.link === 'logout'"
          @click="logout"
          class="mobile-side-bar__link"
        >
          Logout
        </a>
      </div>
      <div class="mobile-side-bar__footer">
        <slot name="footer"></slot>
      </div>
    </div>
    <div
      class="mobile-side-bar__backdrop"
      v-if="showSideBar"
      @click="showSideBar = false"
    ></div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    showSideBar: false
  }),
  computed: {
    filteredList() {
      if (this.showSearch) {
        return this.list.filter(item => {
          return item.name.toLowerCase().includes(this.search.toLowerCase());
        });
      }

      return this.list;
    },
    currentPage() {
      const current = this.list.filter(item => {
        return item.link === this.$route.path;
      });

      return current[0] ? current[0].name : "";
    }
  },
  methods: {
    ...mapActions("user", ["logout"])
  }
};
</script>

<style lang="scss">
.mobile-side-bar {
  width: 100%;
  text-align: center;
  @apply bg-white fixed shadow;
  z-index: 5;

  &__current {
    @apply bg-white p-3 flex items-center justify-center relative;
    z-index: 5;

    &-icon {
      margin-left: 0.5rem;

      &--active {
        transform: rotate(180deg);
      }
    }
  }

  &__links {
    @apply border-t border-ls-gray-200 pt-3 relative bg-white;
    z-index: 5;
  }

  &__item {
    margin-bottom: 1rem;
  }

  &__link {
    @apply text-ls-gray-800 font-semibold text-xs cursor-pointer flex justify-center items-center py-1;

    &--active {
      @apply text-ls-purple;

      .mobile-side-bar__link-icon {
        @apply text-ls-purple;

        path,
        rect {
          @apply fill-current;
        }

        path.templates {
          @apply fill-current;
        }

        path.play-button {
          @apply fill-current;
        }
      }
    }

    &-icon {
      margin-right: 8px;
      @apply text-ls-gray-800;

      path,
      rect {
        @apply fill-current;
      }

      path.templates {
        @apply fill-current;
      }

      path.play-button,
      path.brand-manager,
      path.settings {
        @apply fill-current;
      }

      path.message {
        fill: #6f81e0;
      }
    }
  }

  &__footer {
    @apply flex flex-col;
  }

  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100vh;
    height: 100%;
    width: 100vw;
    background-color: #000;
    opacity: 0.2;
    z-index: 2;
  }
}
</style>
