<template>
  <div class="photo-upload-modal">
    <div class="photo-upload-modal__content">
      <div class="photo-upload-modal__heading">
        {{ $t("components.photoUploadModal.heading") }}
      </div>
      <Button
        class="photo-upload-modal__close-btn"
        @click="onClose"
        icon="X"
        theme="secondary"
      >
        {{ $t("components.photoUploadModal.close") }}
      </Button>
      <div class="photo-upload-modal__content-inner">
        <div class="photo-upload-modal__field" v-if="!showImage && useCropper">
          <input
            id="photo-upload-modal-btn"
            type="file"
            ref="file"
            @change="loadImage"
            accept="image/*"
          />
        </div>
        <div class="photo-upload-modal__field" v-if="!useCropper">
          <input
            id="photo-upload-modal-btn"
            type="file"
            ref="fileInput"
            @change="loadImageWithoutCropper"
            accept="image/*"
          />
        </div>
        <div class="photo-upload-modal__field" v-if="showImage && useCropper">
          <vue-croppie
            class="photo-upload-modal__cropper"
            ref="croppieRef"
            :enableOrientation="true"
            :enableResize="false"
            :enforceBoundary="false"
            :boundary="{ width: 280, height: 280 }"
            :viewport="{ width: 280, height: 280, type: 'square' }"
          >
          </vue-croppie>
        </div>
        <div
          class="photo-upload-modal__preview-without-cropper"
          v-if="image.src"
        >
          <img
            :src="image.src"
            alt="Preview"
            style="max-width: 280px; max-height: 280px;"
          />
        </div>

        <Button class="photo-upload-modal__set-btn" @click="setImage">
          {{ $t("pages.editor.uploadPhoto") }}
        </Button>
      </div>
    </div>
    <div class="photo-upload-modal__backdrop" @click="onClose"></div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Button from "./Button";

export default {
  components: {
    Button
  },
  props: {
    avatarType: {
      type: String,
      default: ""
    },
    uploadType: {
      type: String,
      default: "upload"
    },
    useCropper: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    showImage: false,
    image: {
      src: null,
      name: null
    },
    options: {
      type: "base64",
      size: { width: 480, height: 480 }
    },
    selectedImage: null
  }),
  methods: {
    ...mapActions("user", ["uploadAccountAvatar", "updateAccountAvatar"]),
    ...mapActions("teams", [
      "uploadTeamAvatar",
      "updateTeamAvatar",
      "uploadTeamLogo",
      "updateTeamLogo",
      "uploadTeamLogoDark",
      "updateTeamLogoDark"
    ]),
    onClose() {
      this.$emit("close");
    },
    reset() {
      this.image = {
        src: null,
        name: null
      };
    },
    loadImageWithoutCropper(e) {
      const file = e.target.files[0];

      if (file) {
        this.image.src = URL.createObjectURL(file);
      }
    },
    loadImage(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      let reader = new FileReader();
      this.showImage = true;

      reader.onload = e => {
        this.$refs.croppieRef.bind({
          url: e.target.result
        });
        this.image.name = files[0].name.replace(/\..+$/, "");
      };

      reader.readAsDataURL(files[0]);
    },
    setImage() {
      if (this.useCropper) {
        this.$refs.croppieRef.result(this.options, output => {
          this.image.src = this.croppieImage = output;

          switch (this.uploadType) {
            case "upload":
              this.upload();
              break;
            case "update":
              this.update();
              break;
            default:
              break;
          }
          this.reset();
          this.onClose();
        });
      } else {
        const fileInput = this.$refs.fileInput;
        const file = fileInput.files[0];

        this.image.name = file.name.replace(/\..+$/, "");
        switch (this.uploadType) {
          case "upload":
            this.upload();
            break;
          case "update":
            this.update();
            break;
          default:
            break;
        }
        this.reset();
        this.onClose();
      }
    },
    upload() {
      switch (this.avatarType) {
        case "user":
          this.uploadAccountAvatar(this.image);
          break;
        case "team":
          this.uploadTeamAvatar(this.image);
          break;
        case "logo":
          this.uploadTeamLogo(this.image);
          break;
        case "logoDark":
          this.uploadTeamLogoDark(this.image);
          break;
        default:
          break;
      }
    },
    update() {
      switch (this.avatarType) {
        case "user":
          this.updateAccountAvatar(this.image);
          break;
        case "team":
          this.updateTeamAvatar(this.image);
          break;
        case "logo":
          this.updateTeamLogo(this.image);
          break;
        case "logoDark":
          this.updateTeamLogoDark(this.image);
          break;
        default:
          break;
      }
    }
  },
  destroyed() {
    if (this.image.src) {
      URL.revokeObjectURL(this.image.src);
    }
  }
};
</script>

<style lang="scss">
.photo-upload-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 100;

  .cr-resizer {
    @apply border border-ls-gray-300;
  }

  .cr-boundary {
    @apply border border-ls-gray-300;
  }

  &__content {
    width: calc(100% - 2rem);
    border-radius: 4px;
    background-color: #fff;
    padding: 1.5rem;
    z-index: 5;
    position: absolute;
    height: fit-content;

    &-inner {
      @apply mt-4;
    }
  }

  &__heading {
    font-size: 28px;
    font-weight: 600;
    margin-top: 2.5rem;
  }

  &__close-btn {
    position: absolute;
    top: 24px;
    right: 24px;
  }

  &__set-btn {
    @apply w-full mt-4;
  }

  &__preview-without-cropper {
    @apply flex justify-center mt-4;
  }

  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100vh;
    height: 100%;
    width: 100vw;
    background-color: #000;
    opacity: 0.2;
    z-index: 2;
  }
}

@media screen and (min-width: 900px) {
  .photo-upload-modal {
    &__content {
      min-width: 580px;
      width: auto;
      border-radius: 4px;
      background-color: #fff;
      padding: 1.5rem;
      z-index: 5;
      position: absolute;
    }

    &__heading {
      font-size: 28px;
      font-weight: 600;
      margin-top: 0;
    }
  }
}
</style>
